var site = site || {};
var generic = generic || {};

(function($, ServiceBus, ServiceBusTopics) {
  site.addToCart = function(args) {
    var skuBaseId;
    var isKitItem = false;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return null;
    }
    var catBaseId = '';
    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        cat_base_id = matchResult[0];
      }
    }

    var params = {
      skus: [skuBaseId],
      itemType: 'cart',
      INCREMENT: 1,
      CAT_BASE_ID: catBaseId,
      QTY: args.qty || 1,
    };

    // add replenishment data points if applicable
    if (typeof args.REPLENISHMENT_FREQ !== 'undefined' && args.REPLENISHMENT_FREQ !== false) {
      params.itemType = 'replenishment';
      params.action = 'add';
      params.add_to_cart = 1;
      params.REPLENISHMENT_FREQ = args.REPLENISHMENT_FREQ;
    }
    if (args.skuData && args.skuData.KIT_SKUS) {
      args.kitSkus = args.skuData.KIT_SKUS;
      isKitItem = true;
    } else if (args.kitSkus) {
      isKitItem = true;
    }

    if (isKitItem) {
      site.addKitToCart(args);
      // we call the add to cart as kit, don't need to call the cart.updateCart
      return;
    }
    generic.checkout.cart.updateCart({
      params: params,
      onSuccess: function(r) {
        var messages = r.getMessages();
        var offerCriteriaMessages = '';
        if (messages) {
          messages.forEach(function(message) {
            if (message.key === 'offer_criteria_not_met') {
              offerCriteriaMessages = offerCriteriaMessages.concat(message.text + '<br />');
            }
          });
        }
        if (offerCriteriaMessages) {
          $(document).trigger('addToCart.failure', [messages]);
          generic.overlay.launch({
            content: offerCriteriaMessages,
            includeBackground: true,
            cssStyle: {
              width: '324px',
              padding: '25px'
            }
          });
        } else {
          var resultData = r.getData();
          $(document).trigger('addToCart.success', [resultData]);
        }
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToCart.failure', [errorObjectsArray]);
        //get specs for this... for now show the error message (most likely item count limit reached)

        // Escape any html in the alert box.
        var prodAddedMsg = $('<div/>').html('<span data-test-id="max_error_message">' + errorObjectsArray[0].text + '</span>').html();

        // Send error to overlay
        generic.overlay.launch({
          content: prodAddedMsg,
          includeBackground: true,
          cssStyle: {
            width: '324px',
            padding: '25px'
          }
        });
        //alert(prodAddedMsg);
      }
    });
  };

$(document).on('perlgem.cart.addItem', function(e, skuBaseId, payload) {
  payload = typeof payload !== 'undefined' ? payload : {};
  const args = {};

  // Set sku base id
  args.skuBaseId = skuBaseId;

  // Set quantity
  args.qty = payload.quantity ? payload.quantity : 1;

  // Set replenishment if it exists
  const frequency = payload.replenishment ? payload.replenishment : null;
  if (!!frequency) {
    args.REPLENISHMENT_FREQ = frequency;
  }

  site.addToCart(args);
});

$(document).on("addToCart.success", function() {
  if (ServiceBus && ServiceBus.emit && ServiceBusTopics && ServiceBusTopics.events && ServiceBusTopics.events.CART_UPDATED) {
    ServiceBus.emit(
      ServiceBusTopics.events.CART_UPDATED
    );
  }
});

$(document).on('addToCart.failure', function() {
  if (ServiceBus && ServiceBus.emit && ServiceBusTopics && ServiceBusTopics.events && ServiceBusTopics.events.CART_FAILURE) {
    ServiceBus.emit(
      ServiceBusTopics.events.CART_FAILURE
    );
  }
});

site.addKitToCart = function(params) {
  var args = {};
  var callback = '';
  var eventName = 'addToCartKitItem.success';

  args.COLLECTION_SUBTYPE = params.kitType;
  args.COLLECTION_NAME =  params.collectionName
  args.CAT_BASE_ID = params.kitCat;
  args.itemType = 'kit';

  args.COLLECTION_TYPE = 'UKIT';
  args.SKU_BASE_ID = String(params.kitSkus).split(',');
  args.INCREMENT = 0;
  args.QTY = params.QTY ? params.QTY : 1;
  args._SUBMIT = 'alter_collection';
  args.HAS_MULTIPLE_SKUS = 1;

  if (params.collectionId && params.cartId) {
    args.cmode = 'clear_kit,add,sync';
    args._SUBMIT = 'cart';
    eventName = 'editCartKitItem.success';
    args.COLLECTION_ID = parseInt(params.collectionId);
    args.CART_ID = parseInt(params.cartId);
    callback = site.cartConfirm.clearUrl;

  } else {
    args.COLLECTION_NAME = 'Kit ' + Math.floor((Math.random() * 10000));
    args.action = 'add,edit,create_kit';
    callback = site.cartConfirm.prepareCart;
  }

  generic.jsonrpc.fetch({
    method: 'cart',
    params: [args],
    onFailure: function(ss) {
      console.log(ss.getMessages());
    },
    onBoth: function(r) {
      $(document).trigger(eventName, {'callback': callback, 'cartResult': r.getData()});
    }
  });
  return;
};
site.productData = {
  isActive: function(skuData) {
    return skuData.INVENTORY_STATUS && parseInt(skuData.INVENTORY_STATUS) === 1;
  },
  isTempOutOfStock: function(skuData) {
    return skuData.INVENTORY_STATUS && parseInt(skuData.INVENTORY_STATUS) === 2;
  },
  isComingSoon: function(skuData) {
    return skuData.INVENTORY_STATUS && parseInt(skuData.INVENTORY_STATUS) === 3;
  },
  isInactive: function(skuData) {
    return skuData.INVENTORY_STATUS && parseInt(skuData.INVENTORY_STATUS) === 5;
  },
  isSoldOut: function(skuData) {
    return skuData.INVENTORY_STATUS && parseInt(skuData.INVENTORY_STATUS) === 7;
  },
  isShoppable: function(skuData) {
    return site.productData.isActive(skuData) ||  site.productData.isTempOutOfStock(skuData);
  },
  isSkuDisplayable: function(skuData) {
    var displayable = site.features.spp_displayable_status && site.features.spp_displayable_status[skuData.INVENTORY_STATUS];
    if ((skuData !== undefined) && ((skuData.isOrderable === 1) || (skuData.INVENTORY_STATUS === 3) || (skuData.INVENTORY_STATUS === 2 && Drupal.settings.display_notify_tos) || displayable || (skuData.INVENTORY_STATUS === 2 && skuData.isPreOrder === 1))) {
      return true;
    }
  }
};

  // listen and act on for L2 inventory status updates
  $(document).on('prodcat.status', function(e, data) {
    if (data.result.value !== null) {
      $(data.result.value.products).each(function() {
        $(this.skus).each(function(i, sku) {
        // save the data somewhere
          $(document).data(sku.SKU_ID, sku);
        });
      });
      $(document).trigger('inventory_status_stored');
    }
  });

  //functionality for back button in foundation finder
  $(document).on('click', '#results_back', function() {
    if ($('#ff-content #breadcrumbs').length > 0 && $('#dimensions').is(':empty')) {
      $('#ff-content #breadcrumbs').find('a:last').trigger('click');
    }
  });

  /**
 * Functionality for favorites
 * site.addToFavorite.receiveMessage triggered by post message from MF standalone tool
 */
  site.addToFavorite = site.addToFavorite || {};
  site.addToFavorite.receiveMessage = function(e) {
    if (typeof e !== 'undefined' && typeof e.data !== 'undefined') {
      var receivedMsg = e.data;
      if (receivedMsg.event && receivedMsg.event.indexOf('add_to_favorite') > -1) {
        var skuId = receivedMsg.data.sku;
        if ((typeof site !== 'undefined') && (typeof site.favorites.manipulateWishList !== 'undefined')) {
          site.favorites.manipulateWishList({
            skuBaseId: skuId,
            successCallback: function(r) {
              var $message = $('.icon-heart-message');
              var msg, $product = $('.js-product');
              if ($message.length) {
              // Not needed for mobile. For mobile we're kipping it hidden and showing the messages on an overlay
              //$message.addClass('active');
                if (r.SUCCESS === 1 && r.KEY !== 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
                  $message.addClass('favorites-add-success');
                  $message.removeClass('favorites-add-exists');
                  // Get message for signed out users
                  msg = $('.icon-heart-message.favorites-add-success', $product).find('.favorites-added-signedOut').html();
                } else if (r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
                  $message.addClass('favorites-add-success');
                  $message.removeClass('favorites-add-exists');
                  // Get message for signed in users
                  msg = $('.icon-heart-message.favorites-add-success', $product).find('.favorites-added-signedIn').html();
                } else if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
                  $message.removeClass('favorites-add-success');
                  $message.addClass('favorites-add-exists');
                  // Get favorite already exists message
                  msg = $('.icon-heart-message.favorites-add-exists', $product).find('.favorites-exists').html();
                }
                // Open added to favorites success message in an overlay
                generic.overlay.launch({
                  content: msg,
                  includeBackground: true,
                  hideOnClickOutside: true,
                  includeCloseLink: true
                });
              }
            }
          });
        }
      }
    }
  };

  window.onmessage = () => {
    site.addToFavorite.receiveMessage
  };
})(
  jQuery,
  window.GlobalServiceBus || {},
  window.ServiceBusTopics || {}
);
